<template>
  <div id="app">
    <Web :Config="Config" @UpdateConfig="UpdateConfig"  />
  </div>
</template>

<script>
import Web from './components/Web.vue'
import Config from './config.json'
import Ajax from './Utils/Ajax'

export default {
  name: 'App',
  data(){
      return {
          Config
      }
  },
  created(){
      this.UpdateConfig();
  },
  methods: {

      UpdateConfig(){

          Ajax.post('/getConfig.php', {}, data => {
              this.Config = data;
          });

      }

  },
  components: {
      Web
  }
}
</script>

<style>
  @import './assets/css/style.min.css';
</style>