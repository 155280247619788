export default new class Ajax {

    constructor(){

        this.allow = true; //TODO: ВКЛЮЧИТЬ
        this.url = '/server';

    }

    post(path, data, callback){

        if(this.allow)
            this.ajax('post', path, data, callback);

    }

    get(path, data, callback){

        if(this.allow)
            this.ajax('get', path, data, callback);

    }

    ajax(method, path, data, callback){

        //Заголовки
        let headers = {
            "Content-type": "application/json",
        };

        let fetchData = {
            method:      method,
            cache:       'no-cache',
            credentials: 'same-origin',
            headers:     headers,
            redirect:    'follow',
            referrer:    'no-referrer',
        };

        //Если это POST
        if(method === 'post')
            fetchData.body = JSON.stringify(data);

        //Запрос
        let q = fetch( this.url + path, fetchData);

        if(callback){
            q.then(response => {
                return response.json();
            }).then(data => {
                callback(data);
            });
        }

    }

}