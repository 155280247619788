const Web3 = require("web3");

export default new class EthereumWallet {

    constructor(){
        let network = 'PROD'; //PROD RINKEBY

        if(network === 'PROD') {
            this.contract_address = "0xda97ae50e3385b9b4303a64ceb694d89da78b746";

            this.addressDB = {
                USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7',
                USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
                WBTC: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
                WETH: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
            };

        }else{ //RINKEBY

            this.contract_address = "0x61C6672B71FC3b6BfBabAf6cf7F60E497cF3aBB0";

            this.addressDB = {

                WBTC: '0x577d296678535e4903d59a4c929b718e1d575e0a',
                WETH: '0xc778417e063141139fce010982780140aa0cd5ab'
            };

        }

        this.jsonInterface = [
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "from",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "tokenAddress",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "OnDeposit",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "previousOwner",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "newOwner",
                        "type": "address"
                    }
                ],
                "name": "OwnershipTransferred",
                "type": "event"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "_tokenAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "_amount",
                        "type": "uint256"
                    }
                ],
                "name": "deposit",
                "outputs": [],
                "stateMutability": "payable",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "owner",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "renounceOwnership",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "newOwner",
                        "type": "address"
                    }
                ],
                "name": "transferOwnership",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            }
        ];
        this.tokenJsonInterface = [
            {"constant":false,"inputs":[{"name":"_spender","type":"address"},{"name":"_value","type":"uint256"}],"name":"approve","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function"},
            {"constant":true,"inputs":[{"name":"_owner","type":"address"},{"name":"_spender","type":"address"}],"name":"allowance","outputs":[{"name":"remaining","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"}

        ];

    }

    async connect( callback, accountsChanged ){
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);

            window.ethereum
                .on('accountsChanged', function (accounts) {
                    window.web3.eth.defaultAccount = accounts[0];

                    if(accountsChanged) accountsChanged();
                })
                .on('networkChanged', function () {
                    location.reload();
                })
                .enable()
                .then(function (accounts) {

                    window.web3.eth.defaultAccount = accounts[0];
                    if(callback) callback();
                });

            return true;
        }

        return false;
    }

    toBigFromFloat(sum, decimal){
        sum = sum.toString();
        let split = sum.split('.');
        if(!split[1]) split[1] = "0";

        //Целочисленное значение
        let int = window.web3.utils.toBN( split[0] ).mul( window.web3.utils.toBN(10).pow( window.web3.utils.toBN(decimal) ) );

        //Дробное число
        let dev = window.web3.utils.toBN( split[1] );
        let dev2 = dev.mul( window.web3.utils.toBN(10).pow( window.web3.utils.toBN( decimal - split[1].length ) ) );

        return int.add(dev2).toString();
    }

    async deposit(Currency, sum, transactionHashCallback, confirmationCallback){
        let contract = new window.web3.eth.Contract(this.jsonInterface, this.contract_address),
            sendSum;

        //Конвертация суммы
        switch (Currency) {
            case 'WETH' :
                sendSum = window.web3.utils.toWei(sum);
                break;
            case 'WBTC' :
                sendSum = this.toBigFromFloat(sum, 8);
                break;
            case 'USDC' :
                sendSum = this.toBigFromFloat(sum, 6);
                break;
            case 'USDT' :
                sendSum = this.toBigFromFloat(sum, 6);
                break;
        }

        //Вызов метода
        contract.methods
            .deposit( this.addressDB[ Currency ], sendSum )
            .send({
                from: window.web3.eth.defaultAccount
            })
            .on('transactionHash', transactionHashCallback)
            .on('confirmation', confirmationCallback);
    }

    async allowance(Currency, callback){
        let contract = new window.web3.eth.Contract(this.tokenJsonInterface, this.addressDB[ Currency ]);

        contract.methods
            .allowance( window.web3.eth.defaultAccount, this.contract_address )
            .call()
            .then(callback);

    }


    async approve( Currency, callback, errorCallback ){
        let contract = new window.web3.eth.Contract(this.tokenJsonInterface, this.addressDB[ Currency ]);

        contract.methods
            .approve( this.contract_address, window.web3.utils.toWei('100000000000') )
            .send({
                from: window.web3.eth.defaultAccount
            })
            .then(callback)
            .catch(errorCallback);

    }

    async getNetwork(){
        if(window.web3)
            return await window.web3.eth.net.getId();
        else
            return false;
    }

    getDefaultAddress(){

        return window.web3.eth.defaultAccount;

    }

}