<template>
    <div class="main-content content-contacts">
        <div class="content-wrapp" v-bind:class="{ 'tabs-content--active': Page === name }" id="tab2">
            <div class="content ">
                <div class="content-left">
                    <div class="content-inner">
                        <div class="content-inner__box">
                            <form class="form-left">
                                <input class="form-input" v-model="clientName" type="text" placeholder="Имя" required>
                                <input class="form-input" v-model="clientEmail" type="text" placeholder="E-mail" required>
                                <input class="form-input" v-model="msgTitle" type="text" placeholder="Тема" required>
                            </form>
                            <button @click="action()" :class="{sent: status === 'sent', errorMessage: status === 'error'}" class="button" type="submit">{{ btnText }}</button>
                        </div>
                    </div>
                </div>

                <div class="content-right">
                    <form class="form-right">
                        <textarea class="form-message" v-model="msgText" placeholder="Сообщение"></textarea>
                    </form>
                    <div class="contacts">
                        <div class="contacts-title title">Контакты</div>
                        <div class="contacts-text">Для обращения в службу поддержки - <a
                                href="https://t.me/Usdc_usd_changer" target="_blank">@Telegram</a></div>
                        <div class="contacts-text">Для запросов о сотрудничестве - <a
                                href="https://t.me/Usdc_usd_changer" target="_blank">@Telegram</a></div>
                        <div class="contacts-schedule">Время работы тех поддержки: 24/7</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import Ajax from '../Utils/Ajax'

    export default {
        name: "Contacts",
        props: [ 'Page', 'Config' ],
        data(){
            return {
                btnText:     'Отправить сообщение',

                status:      'notSend',
                name:        "Contacts",
                clientName:  "",
                clientEmail: "",
                msgTitle:    "",
                msgText:     ""
            }
        },
        methods: {
            action(){

                if(this.status === 'notSend')
                    this.send();

                else if(this.status === 'sent')
                    return false;

                else if(this.status === 'error')
                    return false;

            },

            setStatus(status){
                this.status = status;

                if(this.status === 'sent')
                    this.btnText = 'Сообщение отправлено';

                if(this.status === 'error')
                    this.btnText = 'Все поля обязательны';
            },

            send(){
                this.setStatus('sent');

                if(this.clientName !== '' && this.clientEmail !== '' && this.msgTitle !== '' && this.msgText !== ''){

                    Ajax.post('/support.php', {
                        name:  this.clientName,
                        email: this.clientEmail,
                        title: this.msgTitle,
                        text:  this.msgText,
                    }, () => {

                        this.clientName  = '';
                        this.clientEmail = '';
                        this.msgTitle    = '';
                        this.msgText     = '';

                    });

                }else{

                    this.setStatus('error');
                    setTimeout(() => this.setStatus('notSend'), 2000);

                }

            }
        }
    }
</script>