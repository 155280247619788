<template>
    <div class="main-content content-change">
        <div class="content-wrapp" v-bind:class="{ 'tabs-content--active': Page === name }" id="tab1">
            <div class="content content-mob">
                <div class="content-left mob-left">
                    <div class="content-inner">
                        <div v-if="!isAwaiting" class="content-inner__box">
                            <div class="box-top">
                                <h2 class="box-title title">Обмен {{ currency1 }}&nbsp;<br>на USD Cash</h2>
                                <div class="box-clock">
                                    <p>Обновление курса через</p>
                                    <Timer @UpdateConfig="UpdateConfig"/>
                                </div>
                            </div>

                            <div class="box-subtitle">
                                <div class="exchange-title">Курс:</div>
                                <div class="exchange-price">
                                    1 <span>{{ currency1 }}</span> = {{ this.moneyFormat( Config.rates[ this.currency ] ) }}<span> USD</span>
                                </div>
                            </div>

                            <div class="box-operation">
                                <div class="operation">
                                    <p class="operation-text">Отдаете</p>
                                    <div class="operation-send">
                                        <div class="send">
                                            <input v-model="sendValue" @input="updateGet" class="send-input" type="text" placeholder="00.00">

                                            <div class="select select__border" @click="toggleList1" :class="{ 'is-active': this.list1Open }">
                                                <div class="select__header">
                                                    <img :src="require('../assets/img/' + currency1 + '.png')" :alt="currency1">
                                                    <span class="select__current">{{ currency1 }}</span>
                                                </div>

                                                <div class="select__body">
                                                    <div class="select__item" data-choice="choosen" v-for="(rate, index) in this.list1" :key="rate" @click="select1Exchange( index )">
                                                        <div class="select__popup">
                                                            <img :src="require('../assets/img/' + index + '.png')" :alt="index">
                                                            <div>{{ index }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="operation">
                                    <div class="operation-icons">
                                        <div class="icon" style="cursor: default;">
                                            <svg width="8" height="18" viewBox="0 0 8 18" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                        d="M3.64645 0.646446C3.84171 0.451185 4.1583 0.451185 4.35356 0.646446L7.53554 3.82843C7.7308 4.02369 7.7308 4.34027 7.53554 4.53553C7.34027 4.7308 7.02369 4.7308 6.82843 4.53553L4 1.70711L1.17158 4.53553C0.976314 4.7308 0.659731 4.7308 0.464469 4.53553C0.269207 4.34027 0.269207 4.02369 0.464469 3.82843L3.64645 0.646446ZM3.5 18L3.5 1L4.5 1L4.5 18L3.5 18Z"
                                                        fill="white" />
                                            </svg>
                                        </div>
                                        <div class="icon" style="cursor: default;">
                                            <svg width="8" height="18" viewBox="0 0 8 18" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                        d="M3.64645 17.3536C3.84171 17.5488 4.15829 17.5488 4.35355 17.3536L7.53553 14.1716C7.7308 13.9763 7.7308 13.6597 7.53553 13.4645C7.34027 13.2692 7.02369 13.2692 6.82843 13.4645L4 16.2929L1.17157 13.4645C0.976311 13.2692 0.659729 13.2692 0.464467 13.4645C0.269204 13.6597 0.269205 13.9763 0.464467 14.1716L3.64645 17.3536ZM3.5 2.18557e-08L3.5 17L4.5 17L4.5 -2.18557e-08L3.5 2.18557e-08Z"
                                                        fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div class="operation">
                                    <p class="operation-text">Получаете</p>
                                    <div class="operation-recive">
                                        <div class="recive">
                                            <input v-model="getValue" @input="updateSend" class="recive-input" type="text" placeholder="00.00">

                                            <div class="select select__border" @click="toggleList2" :class="{ 'is-active': this.list2Open }">
                                              <div class="select__header">
                                                <img :src="require('../assets/img/' + currency2 + '.png')" :alt="currency2">
                                                <span class="select__current">{{ currency2 }}</span>
                                              </div>

                                              <div class="select__body">
                                                <div class="select__item" data-choice="choosen" v-for="(rate, index) in this.list2" :key="rate" @click="select2Exchange( index )">
                                                  <div class="select__popup">
                                                    <img :src="require('../assets/img/' + index + '.png')" :alt="index">
                                                    <div>{{ index }}</div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="box-bottom">
                                <div class="bottom-choose" v-show="isAllowCity">

                                    <div class="select select__town" @click="townToggleList" :class="{ 'is-active': this.listCity }">
                                        <input class="choose-input" type="text" placeholder="Город">

                                        <div class="select__header">
                                            <span class="select__current current-town">{{ selectedCity }}</span>
                                        </div>

                                        <div class="select__body body-town">

                                            <div
                                                    class="select__item item-town"
                                                    data-choice="choosen"
                                                    v-for="city in Config.cities"
                                                    :key="city"
                                                    @click="selectCity( city )"
                                                    v-bind:class="{ 'item-town__active': city === selectedCity }"
                                            >{{ city }}</div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button class="button button-mob" v-bind:class="{'progress': showProgress}" type="submit" @click="buttonClick()"><span>{{ buttonText }}</span></button>
                        </div>
                        <div v-if="isAwaiting" class="content-inner__box">
                            <div class="contact">
                                <h2 class="contact__title title">Заявка №{{ orderNumber }}</h2>
                                <div class="contact-status">
                                    <p class="order-info">{{ sendValue }} {{ currency1 }} на {{ getValue }} USD Cash</p>
                                    <span class="status-number">1</span>
                                    <h2 class="status-descr">Подтверждение транзакции</h2>
                                    <div class="status-code">
                                        <div class="status-code__item">
                                            <p class="status-code__text">{{ transactionHash }}</p>
                                            <input style="opacity: 0" id="transactionHash" :value="transactionHash">
                                        </div>
                                        <div class="status-code__item">
                                            <a href="#" class="status-code__icon">
                                                <svg @click="copyHash()" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.375 0.0193873C14.2975 0.0193873 6.875 0 6.875 0C5.55249 0 4.37501 1.21313 4.37501 2.50001L3.65251 2.51689C2.33063 2.51689 1.25 3.71312 1.25 5V17.5C1.25 18.7869 2.4275 20 3.75001 20H13.125C14.4475 20 15.625 18.7869 15.625 17.5H16.25C17.5725 17.5 18.75 16.2869 18.75 15V5.01501L14.375 0.0193873ZM13.125 18.75H3.75001C3.09376 18.75 2.50002 18.1369 2.50002 17.5V5C2.50002 4.07001 3.26502 3.75001 4.37501 3.75001V15C4.37501 16.2869 5.55249 17.5 6.875 17.5C6.875 17.5 13.6512 17.4932 14.3806 17.4932C14.3806 18.2519 13.8832 18.75 13.125 18.75ZM14.375 13.1319H8.75001C8.40502 13.1319 8.12502 12.8525 8.12502 12.5075C8.12502 12.1625 8.40502 11.8831 8.75001 11.8831H14.375C14.72 11.8831 15 12.1625 15 12.5075C15 12.8525 14.72 13.1319 14.375 13.1319ZM14.375 10.01H8.75001C8.40502 10.01 8.12502 9.73061 8.12502 9.38564C8.12502 9.04064 8.40502 8.76127 8.75001 8.76127H14.375C14.72 8.76127 15 9.04064 15 9.38564C15 9.73001 14.72 10.01 14.375 10.01ZM15.625 5C14.9594 5 14.375 4.39939 14.375 3.75001C14.375 3.75001 14.375 2.48374 14.375 1.26938V1.26812L17.5 5H15.625Z" fill="#FFC700"></path>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                    <p class="status-confirm">{{ confirmations }}/{{ maxConfirmations }} подтверждений</p>
                                    <Loader v-show="confirmations < maxConfirmations"/>

                                </div>
                                <div class="contact-status">
                                    <span class="status-number">2</span>
                                    <h2 class="status-descr">Получение кода для выдачи наличных</h2>
                                </div>
                            </div>
                            <a href="https://t.me/Usdc_usd_changer" target="_blank" class="button button-mob" type="submit">Связаться с менеджером</a>
                        </div>
                    </div>
                    <div class="new-operation" v-show="confirmations >= maxConfirmations" @click="newOperation" style="text-align: center;margin-top: 9px;cursor: pointer;">Начать новый обмен</div>
                </div>

                <div class="content-right mob-right">
                    <div class="exchange">
                        <div class="exchange-inner">
                            <div class="exchange-inner__top">
                                <h2 class="exchange-title title">Курс у конкурентов</h2>
                                <div class="exchange-items">
                                    <div class="exchange-item">
                                        <div class="exchange-logo">
                                            <img class="exchange-img" src="../assets/img/change/item1.png" alt="">
                                        </div>
                                        <div class="exchange-info">
                                            <div class="exchange-title">Курс обмена </div>
                                            <div class="exchange-price">
                                                1 <span>{{ currency }}</span> =
                                                {{ this.moneyFormat( Config.Competitors.source1[ this.currency ] ) }}<span> USD</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="exchange-item">
                                        <div class="exchange-logo">
                                            <img class="exchange-img" src="../assets/img/change/item2.png" alt="">
                                        </div>
                                        <div class="exchange-info">
                                            <div class="exchange-title">Курс обмена </div>
                                            <div class="exchange-price">
                                                1 <span>{{ currency }}</span> =
                                                {{ this.moneyFormat( Config.Competitors.source2[ this.currency ] ) }}<span> USD</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="exchange-item">
                                        <div class="exchange-logo">
                                            <img class="exchange-img" src="../assets/img/change/item3.png" alt="">
                                        </div>
                                        <div class="exchange-info">
                                            <div class="exchange-title">Курс обмена </div>
                                            <div class="exchange-price">
                                                1 <span>{{ currency }}</span> =
                                                {{ this.moneyFormat( Config.Competitors.source3[ this.currency ] ) }}<span> USD</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="exchange-item">
                                        <div class="exchange-logo">
                                            <img class="exchange-img" src="../assets/img/change/item4.png" alt="">
                                        </div>
                                        <div class="exchange-info">
                                            <div class="exchange-title">Курс обмена </div>
                                            <div class="exchange-price">
                                                1 <span>{{ currency }}</span> =
                                                {{ this.moneyFormat( Config.Competitors.source4[ this.currency ] ) }}<span> USD</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="exchange-inner__mid">
                                <ul class="exchange-status">
                                    <li class="exchange-list">
                                        <span class="list-number">1</span>
                                        <p class="list-text">Утверждение</p>
                                    </li>
                                    <li class="exchange-list">
                                        <span class="list-number">2</span>
                                        <p class="list-text">Оплата</p>
                                    </li>
                                    <li class="exchange-list">
                                        <span class="list-number">3</span>
                                        <p class="list-text">Получение <br> кода</p>
                                    </li>
                                    <li class="exchange-list">
                                        <span class="list-number">4</span>
                                        <p class="list-text">Выдача <br> наличных</p>
                                    </li>
                                </ul>
                            </div>

                            <div class="exchange-inner__bottom">
                                <p class="exchange-text">
                                    После получения оплаты вам необходимо связатся с оператором для получения кода обмена. Предоставьте оператору хэш транзакции либо ссылку на нее, после чего оператор сообщит вам код и адрес пункта выдачи наличных. Актуальный курс обмена фиксируется на момент создания заявки. Подробная инструкция доступна по <a class="descLink" href="https://usdc-usd-exchange.medium.com/%D0%BA%D0%B0%D0%BA-%D1%81%D0%BE%D0%B2%D0%B5%D1%80%D1%88%D0%B8%D1%82%D1%8C-%D0%BE%D0%B1%D0%BC%D0%B5%D0%BD-%D0%BD%D0%B0-%D1%81%D0%B0%D0%B9%D1%82%D0%B5-usdc-usd-exchange-84f90cfc8a93">ссылке</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Utils from '../Utils/Utils.js'
    import EthereumWallet from '../Utils/EthereumWallet'
    import Timer from './Timer'
    import Loader from './Loader'
    import Ajax from '../Utils/Ajax'

    export default {
        name: "Change",
        props: [ 'Page', 'Config'  ],
        data(){
            return {
                Network:     1,
                defaultList: this.Config.rates,
                list1:       this.Config.rates,
                list2:       { USD: 1 },

                selectedCity: this.Config.cities[ 0 ],
                list1Open:    false,
                list2Open:    false,
                listCity:     false,

                isAllowCity:  true, //Показывать ли блок с городом

                name:         "Change",
                currency:      "WETH", //Монета отображаемая у конкурентов
                currency1:     "WETH", //Список 1
                currency2:     "USD", //Список 2
                sendValue:    '',
                getValue:     '',
                buttonStatus: "default",
                buttonText:   "",
                orderNumber:  Utils.getRndInteger(1000, 9999),
                activeOrder:  null,

                showProgress:       false,
                transactionHash:    "",
                isAwaiting:         false,
                confirmations:      0,
                maxConfirmations:   6,
                exchangeSent:       false
            }
        },

        components: {
            Timer,
            Loader
        },

        created(){
            this.buttonMode('connect');

            EthereumWallet.connect( () => this.NetworkStatus(), () => this.NetworkStatus() );
        },

        methods: {
            UpdateConfig(){
                this.$emit('UpdateConfig');

            },

            moneyFormat( value ){

               return Utils.moneyFormat( value );

            },

            townToggleList(){

                this.listCity = !this.listCity;

            },

            //Принимаем город
            selectCity( city ){

                this.selectedCity = city;

            },

            //Открываем или закрываем лист валют
            toggleList1(){
                this.list1Open = !this.list1Open;

                this.list1 = Object.assign({}, this.defaultList);
                delete this.list1[ this.currency2 ];

              if(['WBTC', 'WETH'].includes( this.currency2 )){
                delete this.list1.WBTC;
                delete this.list1.WETH;
              }
            },

            toggleList2(){
                this.list2Open = !this.list2Open;

                this.list2 = Object.assign({ USD: 1 }, this.defaultList);
                delete this.list2[ this.currency1 ];

                if(['WBTC', 'WETH'].includes( this.currency1 )){
                  delete this.list2.WBTC;
                  delete this.list2.WETH;
                }
            },

            //Принимаем значение валюты
            select1Exchange( currency ){

                this.currency1 = currency;
                this.allowance();
                this.updateGet();

                this.currency = this.changeCompetitors();
            },

            select2Exchange( currency ){

                this.currency2 = currency;
                this.allowance();
                this.updateGet();

                this.currency = this.changeCompetitors();
                this.isAllowCity = this.currency2 === 'USD';
            },

            changeCompetitors(){
              let arr = [ this.currency1, this.currency2 ],
                index;

              //Удаляем USDT
              index = arr.indexOf('USDT');
              arr.splice(index, 1);

              if(arr.length === 1)
                return arr[0]

              //Удаляем USDC
              index = arr.indexOf('USDC');
              arr.splice(index, 1);

              //Возвращаем 1
              return arr[0];
            },


            //Текст и анимация кнопки в зависимости от статуса
            buttonMode(status){
                this.buttonStatus = status;

                if( this.buttonStatus === 'toApprove' ){
                    this.showProgress   = false;
                    this.buttonText     = "Утвердить";
                }

                if( this.buttonStatus === 'toExchange' ){
                    this.showProgress   = false;
                    this.buttonText     = "Обменять";
                }

                else if( this.buttonStatus === 'noAddress' ){
                    this.showProgress   = true;
                    this.buttonText = "Нет адреса кошелька!";
                }

                else if( this.buttonStatus === 'waiting' ){
                    this.showProgress   = true;
                    this.buttonText = "Ожидание подтверждения в сети";
                }

                else if( this.buttonStatus === 'connect' ){
                    this.showProgress   = true;
                    this.buttonText = "Подключение MetaMask";
                }

                else if( this.buttonStatus === 'noConnection' ){
                    this.showProgress   = true;
                    this.buttonText = "Не удалось подключить MetaMask";
                }

                else if( this.buttonStatus === 'noSendSum' ){
                    this.showProgress   = true;
                    this.buttonText = "Введите сумму для обмена";
                }

                else if( this.buttonStatus === 'ChangeNetwork' ){
                    this.showProgress   = false;
                    this.buttonText = "Изменить сеть";
                }
            },

            //Действия для кнопки в зависимости от статуса кнопки
            buttonClick(){

                if(this.buttonStatus === 'toApprove')
                    this.toApprove();

                else if( this.buttonStatus === 'toExchange' )
                    this.deposit();

                else if( this.buttonStatus === 'ChangeNetwork' )
                    this.changeNetworkAction();

            },

            //Действие для approve
            toApprove(){
                this.buttonMode('waiting');

                EthereumWallet.approve(this.currency1,
                    () => {
                        this.buttonMode('toExchange');
                    },
                    () => {
                        this.buttonMode('toApprove');
                    },
                );

            },

            //Проверяем, нужен ли approve
            allowance(){

                EthereumWallet.allowance( this.currency1, value => {

                    if(value > 0)
                        this.buttonMode('toExchange');
                    else
                        this.buttonMode('toApprove');

                });

            },

            //Изменение кошелька
            NetworkStatus(){

              EthereumWallet.getNetwork()
                  .then(networkID => {
                    if(networkID === this.Network)
                      this.allowance();
                    else
                      this.buttonMode('ChangeNetwork');
                  });

            },

            dec2Hex(dec) {
              return dec.toString(16);
            },

            async changeNetworkAction() {
              let id = '0x' + this.dec2Hex(1);

              try {
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{chainId: id}],
                });
              } catch (error) {
                if (error.code === 4902) {
                  let data = this.networkData;

                  if (data && data.params) {
                    try {
                      data.params[0].chainId = id;

                      await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: data.params
                      });
                    } catch (addError) {
                      console.error(addError);
                    }
                  }
                }

              }
            },

            //Обращаемся в сеть для обмена
            deposit(){

                if(this.sendValue !== '' && Number(this.sendValue) > 0 && Number(this.getValue) > 0){
                    EthereumWallet.deposit(this.currency1, this.sendValue,
                        hash => {
                            this.activeOrder     = this.orderNumber;

                            this.transactionHash = hash;
                            this.confirmations   = 0;
                            this.isAwaiting      = true;

                        },
                        count => {

                            this.confirmations = count < this.maxConfirmations ? count : this.maxConfirmations;

                            if(this.confirmations > 0 && this.activeOrder === this.orderNumber)
                                this.saveHash();
                        }
                    );
                }else{

                    this.buttonMode('noSendSum');

                    setTimeout( () => this.buttonMode('toExchange'), 2000 );

                }
            },

            updateSend(){

                this.sendValue = this.getValue / this.Config.rates[ this.currency1 ];

            },

            updateGet(){
                if(Number( this.sendValue ) > 0){
                    let value = this.sendValue * this.Config.rates[ this.currency1 ];
                    this.getValue = value.toFixed(2);
                }
            },

            //Отправляем на сервер hash
            saveHash(){

                if(!this.exchangeSent){
                    this.exchangeSent = true;

                    Ajax.post('/exchange.php', {
                        order:     this.orderNumber,
                        hash:      this.transactionHash,
                        address:   EthereumWallet.getDefaultAddress(),
                        getValue:  this.getValue,
                        sendValue: this.sendValue,
                        city:      this.selectedCity,
                        currency1: this.currency1,
                        currency2: this.currency2,
                        rate:      this.Config.rates[ this.currency ]
                    });
                }

            },

            //Операция
            newOperation(){

                location.reload();
            },

            //Копирование хэша
            copyHash(){

                let copyText = document.getElementById("transactionHash");
                copyText.select();
                copyText.setSelectionRange(0, 99999);
                document.execCommand("copy");

            }

        }
    }
</script>