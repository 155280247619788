<template>
    <div class="base-timer">
        <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle">
                <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                <path id="base-timer-path-remaining" v-bind:stroke-dasharray="circleDashArray" class="base-timer__path-remaining orange"
                        d="
                              M 50, 50
                              m -45, 0
                              a 45,45 0 1,0 90,0
                              a 45,45 0 1,0 -90,0
                            "></path>
            </g>
        </svg>
        <span id="base-timer-label" class="base-timer__label">{{ formatTime(timeLeft) }}</span>
    </div>
</template>

<script>
    export default {
        name: "Timer",
        data(){
            return {
                FULL_DASH_ARRAY:    283,
                circleDashArray:    this.FULL_DASH_ARRAY,

                TIME_LIMIT:     30,
                timePassed:     0,
                timeLeft:       this.TIME_LIMIT,
                timerInterval:  null
            }
        },
        created(){
            this.restart();
        },
        methods: {

            restart(){

                this.timePassed = 0;
                this.timeLeft = this.TIME_LIMIT;
                this.start();

            },

            formatTime(time) {
                const minutes = Math.floor(time / 60);
                let seconds = time % 60;

                if (seconds < 10) {
                    seconds = `0${seconds}`;
                }

                return `${minutes}:${seconds}`;
            },

            start(){
                this.timerInterval = setInterval(() => {
                    this.timePassed += 1;
                    this.timeLeft = this.TIME_LIMIT - this.timePassed;
                    this.setCircleDasharray();

                    if (this.timeLeft <= 0)
                        this.onTimesUp();
                }, 1000);

            },

            calculateTimeFraction() {
                const rawTimeFraction = this.timeLeft / this.TIME_LIMIT;
                return rawTimeFraction - (1 / this.TIME_LIMIT) * (1 - rawTimeFraction);
            },

            setCircleDasharray() {

                this.circleDashArray = `${(
                    this.calculateTimeFraction() * this.FULL_DASH_ARRAY
                ).toFixed(0)} 283`;

            },

            onTimesUp(){

                //Сброс счётчика
                clearInterval( this.timerInterval );

                //Получаемый новый конфиг
                this.$emit('UpdateConfig');

                //Перезапуск
                this.restart();

            }

        }
    }
</script>