<template>
    <div>
        <svg class="loader" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
            <circle class="internal-circle" cx="25" cy="25" r="11"></circle>
            <circle class="external-circle" cx="25" cy="25" r="16"></circle>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "Loader"
    }
</script>

<style scoped>

</style>