<template>
    <section class="wrapper">
        <div class="container">
            <main class="main">
                <div class="main-wrapper">
                    <div class="main-bg">
                        <section class="main-inner">
                            <a class="main-logo" href="#">
                                <svg class="main-img" width="64" height="65" viewBox="0 0 64 65" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                            d="M0.15 30.69C-0.42 34.32 1.23 36.33 4.2 36.33H5.07C7.95 36.33 9.39 34.68 10.02 30.69L12.72 13.68H8.73L6 30.96C5.85 31.89 5.61 32.34 4.92 32.34C4.23 32.34 3.99 31.8 4.11 30.96L6.84 13.68H2.85L0.15 30.69ZM15.7849 36.27C18.6649 36.27 20.3749 34.56 20.9149 31.17L21.0949 30.03C21.4249 27.84 20.7049 26.19 19.1749 23.7L17.9449 21.72C17.0749 20.28 16.8649 19.95 17.0449 18.69L17.1649 17.91C17.2549 17.28 17.3749 16.83 18.0649 16.83C18.7849 16.83 18.9349 17.31 18.8149 18L18.3949 20.67H22.1149L22.5349 18C23.0149 14.88 21.7849 13.41 18.7849 13.41H17.9149C15.1849 13.41 13.6849 14.64 13.2049 17.61L13.0549 18.54C12.6649 20.82 13.2349 22.47 14.6749 24.69L15.8449 26.55C16.8349 28.11 17.0749 28.77 16.8349 30.21L16.7149 30.93C16.5049 32.1 16.1449 32.55 15.5149 32.55C14.7949 32.55 14.5849 32.1 14.7349 31.14L15.3049 27.51H11.4649L10.8349 31.47C10.3549 34.59 12.0649 36.27 14.9149 36.27H15.7849ZM21.0783 36H27.0783C30.2883 36 31.1583 34.2 31.6383 30.96L33.6183 18.45C34.1883 15.06 32.6583 13.68 29.7783 13.68H24.6183L21.0783 36ZM27.9183 17.22H28.7883C29.4783 17.22 29.7183 17.73 29.5683 18.72L27.6783 30.63C27.4683 31.92 27.2583 32.28 26.3283 32.28H25.5183L27.9183 17.22ZM38.7553 31.05C38.6053 31.89 38.3953 32.4 37.6753 32.4C36.9553 32.4 36.6553 31.86 36.7753 31.11L38.7853 18.45C38.9353 17.58 39.1453 17.16 39.8953 17.16C40.5853 17.16 40.8853 17.58 40.7653 18.45L40.1653 22.11H44.0353L44.6353 18.3C45.0553 15.72 44.3953 13.38 40.4953 13.38H39.6853C36.2353 13.38 35.2153 15.69 34.7353 18.45L32.7253 31.11C32.3353 33.78 33.1753 36.33 36.9253 36.33H37.7053C41.1553 36.33 42.2353 33.84 42.6253 31.11L43.4053 26.19H39.5353L38.7553 31.05Z"
                                            fill="#FFC700" />
                                    <path
                                            d="M30.15 47.69C29.58 51.32 31.23 53.33 34.2 53.33H35.07C37.95 53.33 39.39 51.68 40.02 47.69L42.72 30.68H38.73L36 47.96C35.85 48.89 35.61 49.34 34.92 49.34C34.23 49.34 33.99 48.8 34.11 47.96L36.84 30.68H32.85L30.15 47.69ZM45.7849 53.27C48.6649 53.27 50.3749 51.56 50.9149 48.17L51.0949 47.03C51.4249 44.84 50.7049 43.19 49.1749 40.7L47.9449 38.72C47.0749 37.28 46.8649 36.95 47.0449 35.69L47.1649 34.91C47.2549 34.28 47.3749 33.83 48.0649 33.83C48.7849 33.83 48.9349 34.31 48.8149 35L48.3949 37.67H52.1149L52.5349 35C53.0149 31.88 51.7849 30.41 48.7849 30.41H47.9149C45.1849 30.41 43.6849 31.64 43.2049 34.61L43.0549 35.54C42.6649 37.82 43.2349 39.47 44.6749 41.69L45.8449 43.55C46.8349 45.11 47.0749 45.77 46.8349 47.21L46.7149 47.93C46.5049 49.1 46.1449 49.55 45.5149 49.55C44.7949 49.55 44.5849 49.1 44.7349 48.14L45.3049 44.51H41.4649L40.8349 48.47C40.3549 51.59 42.0649 53.27 44.9149 53.27H45.7849ZM51.0783 53H57.0783C60.2883 53 61.1583 51.2 61.6383 47.96L63.6183 35.45C64.1883 32.06 62.6583 30.68 59.7783 30.68H54.6183L51.0783 53ZM57.9183 34.22H58.7883C59.4783 34.22 59.7183 34.73 59.5683 35.72L57.6783 47.63C57.4683 48.92 57.2583 49.28 56.3283 49.28H55.5183L57.9183 34.22Z"
                                            fill="#FFC700" />
                                    <path
                                            d="M53.3957 24.2179L53.8914 24.1518L53.8914 24.1518L53.3957 24.2179ZM53.1963 25.3972C53.4157 25.5649 53.7295 25.5231 53.8972 25.3037L56.6307 21.7291C56.7985 21.5097 56.7566 21.1959 56.5373 21.0282C56.3179 20.8605 56.0041 20.9023 55.8364 21.1217L53.4066 24.2991L50.2291 21.8693C50.0098 21.7016 49.696 21.7434 49.5282 21.9628C49.3605 22.1821 49.4023 22.4959 49.6217 22.6637L53.1963 25.3972ZM33.7879 8.83375L33.7341 8.33665L33.7879 8.83375ZM47.3707 13.0702L47.0439 13.4486L47.3707 13.0702ZM52.9001 24.284L53.0044 25.0661L53.9956 24.9339L53.8914 24.1518L52.9001 24.284ZM23.0537 10.4971L33.8416 9.33085L33.7341 8.33665L22.9462 9.50291L23.0537 10.4971ZM33.8416 9.33085C38.6229 8.81396 43.4042 10.3052 47.0439 13.4486L47.6975 12.6918C43.848 9.36719 38.7911 7.78995 33.7341 8.33665L33.8416 9.33085ZM53.8914 24.1518C53.2981 19.7028 51.0945 15.6255 47.6975 12.6918L47.0439 13.4486C50.2557 16.2224 52.3392 20.0774 52.9001 24.284L53.8914 24.1518Z"
                                            fill="white" />
                                    <path
                                            d="M11.4992 41.0433L11.0035 41.1094L11.0035 41.1094L11.4992 41.0433ZM11.6986 39.864C11.4792 39.6963 11.1654 39.7381 10.9977 39.9575L8.26418 43.5321C8.09644 43.7514 8.13828 44.0652 8.35764 44.233C8.57699 44.4007 8.8908 44.3589 9.05854 44.1395L11.4883 40.9621L14.6658 43.3919C14.8851 43.5596 15.1989 43.5178 15.3667 43.2984C15.5344 43.0791 15.4926 42.7653 15.2732 42.5975L11.6986 39.864ZM31.107 56.4274L31.1608 56.9245L31.107 56.4274ZM17.5242 52.191L17.851 51.8126L17.5242 52.191ZM11.9948 40.9772L11.8905 40.1951L10.8993 40.3273L11.0035 41.1094L11.9948 40.9772ZM41.8412 54.7641L31.0533 55.9303L31.1608 56.9245L41.9487 55.7583L41.8412 54.7641ZM31.0533 55.9303C26.272 56.4472 21.4907 54.956 17.851 51.8126L17.1974 52.5694C21.0469 55.894 26.1038 57.4712 31.1608 56.9245L31.0533 55.9303ZM11.0035 41.1094C11.5968 45.5584 13.8004 49.6357 17.1974 52.5694L17.851 51.8126C14.6392 49.0388 12.5557 45.1838 11.9948 40.9772L11.0035 41.1094Z"
                                            fill="white" />
                                </svg>
                            </a>

                            <nav class="main-nav" v-show="Page !== 'installMetaMask'">
                                <a href="#" @click="Page = 'Change'" class="tab tabs-name">Обмен</a>
                                <a href="#" @click="Page = 'Contacts'" class="tab tabs-name">Контакты</a>
                            </nav>

                            <div class="main-status">
                                <div class="status-support">Поддержка онлайн
                                    <span class="status-support__icon status-support__icon--offline"></span>
                                </div>
                            </div>
                        </section>
                    </div>

                    <Change :Page="Page" :Config="Config" @UpdateConfig="UpdateConfig" />
                    <Contacts :Page="Page" :Config="Config"/>
                    <installMetaMask :Page="Page" :Config="Config"/>

                    <a href="https://t.me/Usdc_usd_changer" target="_blank" class="support">
                        <svg class="support-img" width="43" height="43" viewBox="0 0 43 43" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M43 29.1006C43 22.8329 38.7768 17.3569 32.8215 15.7067C32.4472 6.98251 25.2337 0 16.4189 0C7.36536 0 0 7.36536 0 16.4189C0 19.3696 0.785385 22.2424 2.27676 24.7646L0.0603638 32.7772L8.07333 30.5611C10.3918 31.9321 13.0071 32.7044 15.7064 32.8208C17.3562 38.7765 22.8326 43 29.1006 43C31.6024 43 34.0353 42.3337 36.173 41.0677L42.9393 42.9393L41.0677 36.173C42.3337 34.0353 43 31.6024 43 29.1006ZM8.47094 27.8369L3.67399 29.1639L5.00101 24.367L4.69853 23.8939C3.27309 21.6637 2.51953 19.0789 2.51953 16.4189C2.51953 8.75471 8.75471 2.51953 16.4189 2.51953C24.0832 2.51953 30.3184 8.75471 30.3184 16.4189C30.3184 24.0832 24.0832 30.3184 16.4189 30.3184C13.759 30.3184 11.1745 29.5648 8.94401 28.1394L8.47094 27.8369ZM39.326 39.326L35.7665 38.3412L35.2912 38.6505C33.4484 39.8476 31.3075 40.4805 29.1006 40.4805C24.1744 40.4805 19.8456 37.2953 18.3119 32.728C25.8554 31.8583 31.8583 25.8554 32.7283 18.3115C37.2953 19.8456 40.4805 24.1744 40.4805 29.1006C40.4805 31.3075 39.8476 33.4484 38.6505 35.2912L38.3412 35.7665L39.326 39.326Z"
                                    fill="black" />
                            <path d="M15.1592 22.7598H17.6787V25.2793H15.1592V22.7598Z" fill="black" />
                            <path
                                    d="M18.9385 12.5977C18.9385 13.3132 18.6472 13.9736 18.1183 14.4575L15.1592 17.1659V20.2402H17.6787V18.2755L19.8193 16.3163C20.8606 15.3632 21.458 14.008 21.458 12.5977C21.458 9.81895 19.1976 7.55859 16.4189 7.55859C13.6402 7.55859 11.3799 9.81895 11.3799 12.5977H13.8994C13.8994 11.2083 15.0296 10.0781 16.4189 10.0781C17.8083 10.0781 18.9385 11.2083 18.9385 12.5977Z"
                                    fill="black" />
                        </svg>
                    </a>
                </div>
            </main>
        </div>
    </section>
</template>

<script>
    import Change from './Change.vue'
    import Contacts from './Contacts.vue'
    import installMetaMask from './installMetaMask.vue'

    export default {
        name: "Web",
        props: [ 'Config' ],
        created(){

            if(!window.ethereum)
                this.Page = "installMetaMask";

        },
        data(){
            return {
                Page: "Change"
            }
        },
        methods: {

            UpdateConfig(){
                this.$emit('UpdateConfig');
            }

        },
        components: {
            Change,
            Contacts,
            installMetaMask
        }
    }
</script>