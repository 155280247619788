export default new class Utils {

    isUndefined(data){

        return typeof data === 'undefined';

    }

    moneyFormat(val){
        val = Number(val);
        val = val.toFixed(3).replace(/[.,]000$/, "");

        if(!this.isUndefined(val))
            val = val.toString().replace(/\s+/g,'').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
        else
            val = '';

        return val;
    }

    getRndInteger (min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
    }

}