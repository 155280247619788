<template>
    <div class="main-content content-contacts">
        <div class="content-wrapp" v-bind:class="{ 'tabs-content--active': Page === name }" id="tab2">
            <div class="content ">
                <div class="content-right" style="width:100%;margin-top:50px;">
                    <div style="text-align: center;">
                        <div>
                            <img src="../assets/img/metaMask.png">
                        </div>
                        <div class="title">
                            Расширение для браузера MetaMask не найдено!<br> Посетите официальный сайт <a href="https://metamask.io/download.html" target="_blank">metamask.io</a> для его установки
                        </div>

                        <div style="max-width: 300px; margin: 60px auto;">
                            <a href="https://metamask.io/download.html" target="_blank" class="button" style="padding:0">Установить MetaMask</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "installMetaMask",
        props: [ 'Page', 'Config' ],
        data(){
            return {
                name: "installMetaMask",
            }
        }
    }
</script>

<style scoped>

</style>